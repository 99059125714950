import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export interface EnvConfig {
    USER_POOL_ID: string;
    USER_POOL_CLIENT_ID: string;
    SLOTH_API_URL: string;
    OAUTH_DOMAIN: string;
    REDIRECT_SIGN_IN: string;
    REDIRECT_SIGN_OUT: string;
    TAG_URL: string;
    GBUCKET_SYNC: string;
    ENVIRONMENT: string;
    TENANT: string;
    CSS_URL: string

    isLoaded: boolean;
}

const initialState: EnvConfig = {
    USER_POOL_ID: '',
    USER_POOL_CLIENT_ID: '',
    SLOTH_API_URL: '',
    OAUTH_DOMAIN: '',
    REDIRECT_SIGN_IN: '',
    REDIRECT_SIGN_OUT: '',
    TAG_URL: '',
    GBUCKET_SYNC: '',
    isLoaded: false,
    ENVIRONMENT: '',
    TENANT: '',
    CSS_URL: ''

};

export const fetchEnvConfig = createAsyncThunk(
    'envConfig/fetch',
    async () => {
        const response = await fetch('./environment.json');
        return response.json();
    }
);

const envConfigSlice = createSlice({
    name: 'envConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEnvConfig.fulfilled, (state, action) => {
            return { ...state, ...action.payload, isLoaded: true };
        });
    },
});
export default envConfigSlice.reducer;