import { configureStore } from '@reduxjs/toolkit'
import {
    useSelector as rawUseSelector,
    TypedUseSelectorHook,
} from "react-redux";
import userRoleReducer from './features/userRoleSlice'
import envConfigReducer, { fetchEnvConfig } from './features/envConfigSlice'
import { slothAPI } from "./api/slothAPI";

export const store = configureStore({
    reducer: {
        userRole: userRoleReducer,
        envConfig: envConfigReducer,
        [slothAPI.reducerPath]: slothAPI.reducer,
    },
    devTools: true, // Enable Redux DevTools for debugging
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([slothAPI.middleware]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

let configLoadPromise: Promise<void> | null = null;

export const ensureConfigLoaded = () => {
    if (!configLoadPromise) {
        configLoadPromise = new Promise<void>((resolve) => {
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                if (state.envConfig.isLoaded) {
                    unsubscribe();
                    resolve();
                }
            });
            store.dispatch(fetchEnvConfig());
        });
    }
    return configLoadPromise;
};

// Initialize config loading
ensureConfigLoaded();